.only-print {
  display: none !important;
}

@media print {
  .noprint,
  .save-btn {
    display: none !important;
  }

  .custom-page-container {
    overflow: visible;
  }
  .only-print {
    display: table-cell !important;
  }
  .only-print-d-block {
    display: flex !important;
  }
}
