$theme-colors: (
  "primary": #07629e,
  "secondary": #1f1f1f,
  "tertiary": #292c2f,
  "success": #017d0a,
  "info": #bfc0c1,
  "warning": #a56d02,
  "dark": #121212,
  "danger": #ad2736,
  "light": #232429,
  "light-trans": rgba(35, 36, 41, 0.7),
  "default": #3b3b3b,
  "trans": rgba(0, 0, 0, 0),
  "input": #a8a8a8,
  "content": #1d1d1d,
  "white": #9c9c9c,
);

$border-radius: 10px;
$ligthtingIntensity: 10%;
$darkeningIntensity: 10%;
$body: #121212;
