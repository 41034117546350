.page-item {
  .page-link {
    border: 0;
    background: theme-color("dark");
    color: theme-color("white");
  }
  &.active {
    .page-link {
      color: white;
    }
  }
}
