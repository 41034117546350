/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 100%;
  height: 20px;
  left: 0px;
  top: 0px;
  padding: 40px 0;
  padding-top: 20px;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(7px);

  span {
    width: 26px;
    overflow: hidden;
    display: block;
    position: relative;
    left: 26px;
    height: 20px;

    .bm-burger-bars {
      background: #b7b7b7;
    }
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(35, 36, 41, 0.8);
  backdrop-filter: blur(15px);
  padding: 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 20px 0;
  padding-top: 40px;
}

/* Individual item */
.bm-item {
  display: inline-block;
  font-size: 16px;
  padding: 14px 1.8rem;
  font-weight: bold;
  text-decoration: none !important;
  color: white;

  &.active {
    //color: #3382b8;
  }
}
.menu-item[data-attr="active"] {
  //color: #3382b8 !important;
}
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
