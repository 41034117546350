.arrow-border-right {
  position: relative;
}
.arrow-border-right:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);

  border-width: 7px;
  margin-top: -7px;
}
.arrow-border-right.badge-success:after {
  border-left-color: #36767e;
}
.arrow-border-right.badge-danger:after {
  border-left-color: #dc5f45;
}
.cycle-timeline .vertical-timeline-element-date {
  position: absolute;
  top: 14px;
  font-size: 24px;
  font-weight: 800 !important;
  color: #36767e;
}
