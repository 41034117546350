.custom-table-container {
  box-shadow: 0 0 20px -8px black;
  background: transparent;
  backdrop-filter: blur(7px);
}
.custom-table {
  overflow: hidden;
  background: rgba(28, 28, 33, 0.63);
  border-radius: 10px;

  th {
    font-size: 14px;
    color: #b7b7b7;
    padding: 10px 0;
    border-bottom: 0;
  }

  td {
    font-size: 15px;
    font-weight: 500;
    color: white;
  }
}
.table-small-padding {
  font-family: system-ui;
  font-size: 13px;
  td {
    font-size: 12px;
    padding: 5px !important;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(39, 38, 41, 0.34) !important;
}
