@media (max-width: 900px) {
  html,
  body {
    font-size: 12px;

    .form-control {
      font-size: 12px;
    }
  }
  .acc-btn {
    flex-direction: column;
  }
  .user-title-container {
    .user-title-header {
      // display: block !important;
      flex-direction: column !important;
      width: 100%;
      width: 100%;
      text-align: center !important;

      .user-title-icon {
        width: 100%;
        margin-bottom: 10px;
        flex-direction: row !important;
        justify-content: center;
        margin-right: 0 !important;
        .fa {
          margin-right: 5px;
        }
      }
      .user-title-info {
        text-align: center !important;
        width: 92%;
      }
    }
    .user-title-content {
      justify-content: center !important;
      width: 100%;
      margin: 10px 0;
    }
  }
  .nav-tabs .nav-item {
    width: 100% !important;
  }
  .overlay-bottom {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .page-container {
    padding: 0 30px !important;
  }
  #content {
    //padding: 0px !important;
  }
}

@media (max-width: 600px) {
  #sidebar.active {
    margin-left: -270px !important;

    ul li a {
      top: 40px;

      &.active {
        transform: none !important;
      }
    }
  }

  .top-header {
    padding: 20px 0 !important;
    max-width: 90% !important;

    .top-dropdown.show .avatar-dropdown span {
      padding-left: 10px !important;
      width: auto !important;
    }
  }
  .readonly .input-group {
    width: 100%;
  }
  .readonly label {
    width: 100%;
    margin-top: 10px;
  }
  .page-container {
    max-width: 100% !important;
  }
  h2 {
    font-size: 18px;
    line-height: 22px;
    flex-direction: column !important;
  }
  .title-blue {
    font-size: 22px !important;
    display: block;
    text-align: center;
  }
  // table tbody td:not(:first-child):not(:last-child),
  // table thead {
  //   display: none;
  // }
  .cta-box {
    margin: 0 auto;
  }
  .cta-block {
    justify-content: center;
    align-items: center;

    .ic-nav {
      display: block;
    }
  }
  .cta-container {
    height: 280px;
    overflow: hidden;
  }
}
