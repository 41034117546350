.main-title {
  color: theme-color("secondary");
  font-size: 1.5rem;
  font-weight: 600;
}

h1,
.h1 {
  color: theme-color("white");
  font-weight: bold;
  margin-bottom: 0;
  font-size: 1.6rem;
}
h2,
.h2 {
  color: theme-color("default");
  font-size: 25px;
  line-height: 1.2rem;
  margin-bottom: 0;
}
h3,
.h3 {
  font-weight: bold;
}
h4,
.h4 {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 28px;
  color: theme-color("secondary");
}
h5,
.h5 {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 28px;
  color: theme-color("secondary");
}
.border-bottom-light {
  border-bottom: 1px solid #e2e8ed;
}
